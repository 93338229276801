import { gql } from 'graphql-request';

export const saveCustomerGql = gql`
  mutation saveCustomerPrescriptions(
    $firstName: String!,
    $lastName: String!,
    $gender: String!,
    $age: Int,
    $mobileNumber: String,
    $countryCodeMobile: String,
    $email: String,
    $language: String!,
    $dateOfBirth: String,
    $countryCode: String,
    $id: String,
    $prescriptions: [NewPrescription]!,
    $mobileVerified: Boolean
    $staffId: Int
  ) {
    saveCustomerPrescriptions(
      input: {
        firstName: $firstName,
        lastName: $lastName,
        gender: $gender,
        age: $age,
        language: $language,
        email: $email,
        dateOfBirth: $dateOfBirth,
        countryCode: $countryCode,
        prescriptions: $prescriptions,
        mobileNumber: $mobileNumber,
        countryCodeMobile: $countryCodeMobile,
        id: $id,
        mobileVerified: $mobileVerified,
        staffId: $staffId
      }
    ) { firstName, lastName, id, phone, gender age, mobileNumber, mobileVerified, latestPrescriptionId }
  }
`;

// OLD
export const getCustomerGql = gql`
  query ($term: String!) {
    getCustomer(term: $term) {
      firstName
      lastName
      language
      age
      email
      phone
      gender
      dateOfBirth
      id
      warehouseGroup
      prescriptions {
        id
        name
        lensType
        createdAt
        staffName
        storeName
        prescriptionUrl
        relationshipType
        comment {
          comment
        }
        right {
          sphere
          cylinder
          axis
          additionalPower
          segmentHeight
          pupilDiameter
          visualAcuityCategory
          visualAcuityValue
        }
        left {
          sphere
          cylinder
          axis
          additionalPower
          segmentHeight
          pupilDiameter
          visualAcuityCategory
          visualAcuityValue
        }
      }
    }
  }
`;

export const getCustomerByCountryGql = gql`
  query ($term: String!, $countryCode: String!) {
    getCustomerByCountry(term: $term, countryCode: $countryCode) {
      firstName
      lastName
      language
      age
      email
      phone
      gender
      dateOfBirth
      id
      mobileNumber
      countryCodeMobile
      prescriptions {
        id
        name
        lensType
        createdAt
        staffName
        storeName
        prescriptionUrl
        relationshipType
        prescriptionDoctorName
        externalPrescription
        corridor
        comment {
          comment
        }
        right {
          sphere
          cylinder
          axis
          additionalPower
          segmentHeight
          pupilDiameter
          visualAcuityCategory
          visualAcuityValue
        }
        left {
          sphere
          cylinder
          axis
          additionalPower
          segmentHeight
          pupilDiameter
          visualAcuityCategory
          visualAcuityValue
        }
      }
    }
  }
`;

export const getOrderGql = gql`
  query ($id: String!) {
    getOrder(id: $id) {
      id
      customerId
      orderNumber
      relatedOrder
      orderStatus
      secondaryOrderStatus
      orderCreatedAt
      secondaryOrderStatusTimestamp
      cancellationReason
      paymentMethod
      clientName
      phone
      email
      address
      city
      country
      store
      posStoreName
      shippingType
      discountCode
      shippingCourierName
      magentoDbId
      magentoStatus
      timestamp
      shippingDate
      deliveryDate
      awbNumber
      orderTrackingNumber
      prescriptionType
      transitCourierName
      transitAwbNumber
      totalSkus
      grandTotal
      discountAmount
      shippingFee
      codFee
      erpOrderId
      receiptId
      lastPrescriptionUpdatedAt
      lastPrescriptionUpdatedAtERP
      products {
        id
        sku
        name
        status
        category
        quantity
        stockCount
        requireFitting
        parentItemId
        childItemId
        prescriptionDoctorName
        externalPrescription
        childProducts {
          id
          sku
          name
        }
        status
        lensWidth
        noseBridge
        appliedPrescriptionId
        frame {
          type
          shape
          size
          width
          material
        }
        power {
          lensShape
          lensType
          lensShape
          left {
            sphere
            cylinder
            axis
            pupilDiameter
            additionalPower
            segmentHeight
          }
          right {
            sphere
            cylinder
            axis
            pupilDiameter
            additionalPower
            segmentHeight
          }
          prescriptionUrl
          comment {
            id
            userEmail
            comment
            createdAt
          }
          addOn1
          addOn2
          addOn3
          addOn4
          addOn5
          addOn6
          addOn7
          addOn8
          addOnPackage
          addOnColor
          index
          lensDiameter
          baseCurve
          corridor
        }
        price
        originalPrice
        priceInclTax
        baseOriginalPrice
        taxAmount
        baseDiscountAmount
        discountAmount
        rowTotalInclTax
        subTotal
      }
      comments {
        id
        userEmail
        comment
        createdAt
      }
      customerCommunicationComments {
        id
        staffName
        channel
        comment
        createdAt
      }
      fulfilmentCentre {
        id
        name
      }
      shippedToFc {
        id
        name
      }
    }
  }
`;

export const addCommentGql = gql`
  mutation addComment(
    $orderId: String
    $comment: String
  ) {
    addComment(
      input: {
        orderId: $orderId
        comment: $comment
      }
    ) {
      id
      userEmail
      comment
      createdAt
    }
  }
`;

export const addCustomerCommunicationCommentGql = gql`
  mutation addCustomerCommunicationComment(
    $orderId: String
    $staffName: String
    $channel: String
    $comment: String
  ) {
    addCustomerCommunicationComment(
      input: {
        orderId: $orderId
        staffName: $staffName
        channel: $channel
        comment: $comment
      }
    ) {
      id
      staffName
      channel
      comment
      createdAt
    }
  }
`;

export const updateOrderGql = gql`
mutation updateOrder(
  $id: String!,
  $staffName: String,
  $storeName: String,
  $orderNumber:String!,
  $products: [ProductRequest]!) {
  updateOrder(
    input: {
      id: $id,
      staffName: $staffName,
      storeName: $storeName,
      orderNumber:$orderNumber,
      products: $products
    }
  ) {
   id
  }
}
`;

export const bulkUploadCommunicationCommentsGql = gql`
  mutation bulkUploadCommunicationComments(
  $communicationComments: [CustomerCommunicationCommentRequest]!) {
  bulkUploadCommunicationComments(
    input: {
      communicationComments: $communicationComments
    }) {
      id
      staffName
      userEmail
      channel
      comment
      createdAt
    }
  }
`;

export const bulkUploadSkuIdsGql = gql`
  mutation bulkUploadSkuIDs(
    $skuIds: [String]!
  ) {
    bulkUploadSkuIDs(
      input: {
        skuIds: $skuIds
      }
    )
  }
`;

export const bulkUploadStaffNameGql = gql`
  mutation bulkUploadStaffName (
    $input: BulkStaffDetail
  ) {
    bulkUploadStaffName (input: $input)
  }
`;

export const bulkUploadUsersGql = gql`
  mutation bulkUploadUsers(
    $input: UserList
  ) {
    bulkUploadUsers (input: $input)
  }
`;

export const getUsersGql = gql`
  query getUsers {
    getUsers {
      email
      name
      roles
      stores
    }
  }
`;

export const getStoresGql = gql`
  query getStores {
    getStores {
      code
      centreName
      city
      country
    }
  }
`;

export const getCustomersGql = gql`
  query ($filter: CustomersFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getCustomers(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      data {
        firstName
        lastName
        email
        phone
        prescriptions {
          lensType
          staffName
          storeName
          createdAt
          externalPrescription
          prescriptionDoctorName
          comment {
            comment
          }
        }
        createdAt
      }
      meta {
        totalCount
        page
        perPage
      }
    }
  }
`;

export const getSummaryGql = gql`
  query ($filter: SummaryFilter) {
    getSummary(filter: $filter) {
      customers
      prescriptions
    }
  }
`;

export const getDefaultConfigsGql = gql`
  query {
    getDefaultConfigs {
      staffName
      store
      country
    }
  }
`;

export const updateDefaultConfigsGql = gql`
  mutation (
    $UpdateConfigRequest: UpdateConfigRequest
  ) {
    updateDefaultConfigs(input: $UpdateConfigRequest) {
      staffName
      store
      country
    }
  }
`;

export const getStaffGql = gql`
  query getStaffDetails {
    getStaffDetails {
      name
    }
  }
`;

export const getPredictedCustomersGql = gql`
  query ($filter: CustomersFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getCustomers(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      data {
        firstName
        lastName
        email
        id
        mobileVerified
        mobileNumber
        gender
        language
        dateOfBirth
        age
      }
    }
  }
`;

export const getNewestCustomersGql = gql`
  query ($filter: CustomersFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getCustomers(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      data {
        firstName
        lastName
        email
        mobileNumber
        countryCodeMobile
        id
        prescriptions { createdAt }
      }
    }
  }
`;

export const getCustomerInfoGql = gql`
  query ($term: String!) {
    getCustomer(term: $term) {
      id
      firstName
      lastName
      language
      email
      phone
      gender
      age
      dateOfBirth
      mobileNumber
      mobileVerified
      countryCodeMobile
      prescriptions { id }
    }
  }
`;

export const getPredictedOrdersGql = gql`
  query ($filter: OrderFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getOrders(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      id
    }
  }
`;

export const getOrdersGql = gql`
  query ($filter: OrderFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getOrders(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      id,
      orderCreatedAt
      clientName
      orderStatus
      fulfilmentCentre { name }
    }
  }
`;

export const getNewestOrdersGql = gql`
  query ($filter: OrderFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getOrders(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      id
      orderStatus
      orderCreatedAt
      clientName
    }
  }
`;

export const getPendingPrescriptionOrdersGql = gql`
  query ($filter: OrderFilter, $sortBy: String, $page: Int, $perPage: Int) {
    getPendingPrescriptionOrders(
      filter: $filter
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      id,
      orderCreatedAt
      clientName
      orderStatus
      fulfilmentCentre { name }
    }
  }
`;
