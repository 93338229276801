import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './List.module.scss'
import { useNavigate, useParams } from 'react-router'
import { InfoCard } from 'components'
import useI18n from 'common-hooks/useI18n'
import prescriptionIcon from './prescription.png'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectDefaults, selectStores } from 'appSlice'
import { LENS_TYPE_MAPPINGS } from 'components/prescription/prescription-constants';
import Portal from 'components/portal/Portal'
import PopUpDialog from 'components/pop-up/PopUp'
import PrescriptionPrintPreview from 'pages/orders/prescription-print-preview/PrescriptionPrintPreview'

const ARROW_DOWN = <svg width="12" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M0.528758 0.528758C0.789108 0.268409 1.21122 0.268409 1.47157 0.528758L5.00016 4.05735L8.52876 0.528758C8.78911 0.268409 9.21122 0.268409 9.47157 0.528758C9.73192 0.789108 9.73192 1.21122 9.47157 1.47157L5.47157 5.47157C5.21122 5.73192 4.78911 5.73192 4.52876 5.47157L0.528758 1.47157C0.268409 1.21122 0.268409 0.789108 0.528758 0.528758Z" fill="#31395F" /> </svg>
const ARROW_UP = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.7402 6.2207L5.75977 1.24023L0.779297 6.2207" stroke="#31395F" strokeOpacity="0.5" strokeWidth="1.40625" strokeLinecap="round" strokeLinejoin="round" /> </svg>


function List({ email, items, customer, comprehensiveItems }) {
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const { id } = useParams();
  const stores = useSelector(selectStores);
  const { defaultWarehouse } = useSelector(selectDefaults);
  const containerRef = useRef(null)
  const dialogRef = useRef(null);
  const [printPreviewData, setPrintPreviewData] = useState(null)

  const goToAdd = () => {
    navigate(`/customers/${email}/prescriptions/add`)
  }

  const goToAddCee = () => {
    navigate(`/customers/${email}/prescriptions/add-cee`)
  }

  function getIndexToInsertSelected() {
    if (!id) return 0;
    const n = items.findIndex(x => x.id === id) + 1;
    if (n === -1) return 0;
    if (n % 3 === 0) return n;
    return n + (3 - (n % 3));
  }

  const handleClick = _id => {
    let endpoint = `/${_id}`;
    if (id === _id) {
      endpoint = '';
    }

    navigate(`/customers/${email}/prescriptions${endpoint}`)
  }

  const handleComprehensiveItemClick = _id => {
    let endpoint = `/${_id}/cee`;
    if (id === _id) {
      endpoint = '';
    }

    navigate(`/customers/${email}/prescriptions${endpoint}`)
  }

  const handleCEEPrint = (e, _id) => {
    e.preventDefault();
    e.stopPropagation();
    let endpoint = `/${_id}/cee`;
    navigate(`/customers/${email}/prescriptions${endpoint}`, { state: { print: true } })
  }

  const handleDialog = (e, itemId) => {
    e.preventDefault();
    // e.stopPropagation();
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
    let selectedPrescription = items.find(x => x.id === itemId);
    setPrintPreviewData(selectedPrescription)
  }

  const renderPrintPreview = () => {
    if (printPreviewData) {
      return (
        <PrescriptionPrintPreview enableCheckboxes={true} {...printPreviewData} country={customer?.country?.toUpperCase()} containerRef={containerRef} dialogRef={dialogRef} customerName={`${customer?.firstName} ${customer?.lastName}`} customerId={customer?.id} customerPhone={customer?.phone} />
      )
    }
  }

  const renderField = (label, value, disabled) => (
    <li className={cx(styles.field, { [styles.disabled]: disabled })}>
      <span className={cx(styles.label)}>{label}</span>
      <span className={cx(styles.value)}>{disabled ? '0.00' : new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
        value
      )}</span>
    </li>
  )

  const renderEye = (item, title, lensType) => {
    return (
      <>
        <h4 className={cx(styles['eye-title'])}>{title} <span className={cx(styles['visual-acuity'])}>{[item.visualAcuityCategory, item.visualAcuityValue].filter(Boolean).join(': ')}</span></h4>
        <ul className={cx(styles.fields)}>
          {renderField(i18n('SPH'), item.sphere, !LENS_TYPE_MAPPINGS[lensType]?.sphere)}
          {renderField(i18n('CYL'), item.cylinder, !LENS_TYPE_MAPPINGS[lensType]?.cylinder)}
          {renderField(i18n('AXIS'), item.axis, !LENS_TYPE_MAPPINGS[lensType]?.axis)}
          {renderField(i18n('IPD'), item.pupilDiameter, !LENS_TYPE_MAPPINGS[lensType]?.pupilDiameter)}
          {renderField(i18n('ADD'), item.additionalPower, !LENS_TYPE_MAPPINGS[lensType]?.additionalPower)}
          {renderField(i18n('SH'), item.segmentHeight, !LENS_TYPE_MAPPINGS[lensType]?.segmentHeight)}
        </ul>
      </>
    )
  }

  const renderSelectedItem = () => {
    const item = items.find(x => x.id === id);

    return (
      <div key={`${id}-selected`} className={cx(styles['selected-item'])}>
        <div className={cx(styles.cols)}>
          <div className={cx(styles.col)}>
            {renderEye(item.right, i18n('Right eye (OD)'), item.lensType)}
          </div>
          <div className={cx(styles.col)}>
            {renderEye(item.left, i18n('Left eye (OS)'), item.lensType)}
          </div>
        </div>
        <div className={cx(styles.commentAndPrintWrapper)}>
          {!!item.comment?.comment && <p className={cx(styles.comment)}>
            <span className={cx(styles['comment-title'])}>{i18n('Comment')}</span>
            <span className={cx(styles['comment-body'])}>{item.comment?.comment}</span>
          </p>}
        </div>
      </div>
    )
  }

  const renderIcon = item => item.prescriptionUrl ? (
    <a href={item.prescriptionUrl} target="_blank" rel="noopener noreferrer">
      <img className={cx(styles.icon)} src={item.prescriptionUrl} alt="" />
    </a>
  ) : (
    <img className={cx(styles.icon)} src={prescriptionIcon} alt="" />
  )

  const renderItems = () => {
    const indexToInsertSelected = getIndexToInsertSelected();
    const nodes = items.map(item => (
      <li key={item.id} className={cx(styles.item, { [cx(styles.selected)]: item.id === id })} onClick={() => handleClick(item.id)}>
        <div className={cx(styles.header)}>
          {renderIcon(item)}
          <div className={cx(styles['name-date-wrapper'])}>
            <h2 className={cx(styles.name)}>{[item.id, item.name, item.relationshipType].filter(Boolean).join(' | ')}</h2>
            <p className={cx(styles.date)}>{`${i18n('')} ${dayjs(item.createdAt).format('D MMMM, YYYY')}`}</p>
            <p className={styles['since-last-eyetest']}>
              <span>{'('}</span>
              <span>{dayjs().diff(dayjs(item.createdAt), 'years') ? `${dayjs().diff(dayjs(item.createdAt), 'years')} years ` : ''}</span>
              <span>{dayjs().diff(dayjs(item.createdAt), 'months') ? `${dayjs().diff(dayjs(item.createdAt), 'months') % 12} months ` : ''}</span>
              <span>{dayjs().diff(dayjs(item.createdAt), 'days') ? `${dayjs().diff(dayjs(item.createdAt), 'days') % 30} days ` : ''}</span>
              <span>{dayjs().diff(dayjs(item.createdAt), 'hours') ? ' since last eye test': ''}</span>
              <span>{')'}</span>
            </p>
          </div>
          {id === item.id ? ARROW_UP : ARROW_DOWN}
        </div>
        <div className={cx(styles.content)}>
          <h3 className={cx(styles.lens)}>{item.lensType}</h3>
          <p className={cx(styles.staff)}>{'Corridor: ' + item.corridor}</p>
          <p className={cx(styles.staff)}>{[item.staffName, stores.find(x => x.value === item.storeName)?.label].filter(Boolean).join(', ')}</p>
          <p className={cx(styles.source)}>{item.externalPrescription ? item.prescriptionDoctorName || i18n('Other') : i18n('Internal')}</p>
          <div className={styles.printButton} onClick={(e) => handleDialog(e, item.id)}>
            <span>{'Print'}</span>
          </div>
        </div>
      </li>
    ));

    if (indexToInsertSelected) nodes.splice(indexToInsertSelected, 0, renderSelectedItem());
    return nodes;
  }

  const renderItemsComprehensive = () => {
    return comprehensiveItems.map(item => (
      <li key={item.id} className={cx(styles.item, { [cx(styles.selected)]: item.id === id })} onClick={() => handleComprehensiveItemClick(item.id)}>
        <div className={cx(styles.header)}>
          {renderIcon(item)}
          <div className={cx(styles['name-date-wrapper'])}>
            <h2 className={cx(styles.name)}>{[item.id, item?.data?.fullName].filter(Boolean).join(' | ')}</h2>
          </div>
        </div>
        <div className={cx(styles.content)}>
          <h3 className={cx(styles.lens)}>{`Impression: ${item?.data?.impression ? item?.data?.impression : ''}`}</h3>
          <p className={cx(styles.staff)}>{`Management: ${item?.data?.management ? item?.data?.management : ''}`}</p>
          <p className={cx(styles.staff)}>{[item?.data?.staffName, item?.data?.optometrist].filter(Boolean).join(', ')}</p>
          <div className={styles.printButton} onClick={(e) => handleCEEPrint(e, item.id)}>
            <span>{'Print'}</span>
          </div>
        </div>
      </li>
    ));
  }

  return (
    <>
      <Portal containerRef={containerRef}>
        <PopUpDialog ref={dialogRef} title="Print Preview" showCloseButton backdropClose dialogClassname={styles.dialogClassname} >
          <div>
            {renderPrintPreview()}
          </div>
        </PopUpDialog>
      </Portal>
      <InfoCard title={i18n('Prescriptions')} cta={i18n('Add')} onCtaClick={goToAdd} secondaryCta={defaultWarehouse?.comprehensiveETBEnabled ? i18n('Add CEE Report') : null} onSecondaryCtaClick={goToAddCee}>
        {items.length ? (
          <ul className={cx(styles.items)}>{renderItems()}</ul>
        ) : <>
          <p>{i18n('No prescriptions')}</p>
          <p>{i18n('-')}</p>
        </>
        }
        {comprehensiveItems?.length > 0 ? (
          <>
            <h4 className={cx(styles.title)}>Comprehensive Prescriptions</h4>
            <div className={cx(styles.divider)}></div>
            <ul className={cx(styles.items)}>{renderItemsComprehensive()}</ul>
          </>
        ) : <></>}
      </InfoCard>
    </>

  )
}

List.defaultProps = {
  items: []
}

List.propTypes = {
  email: PropTypes.string,
  items: PropTypes.array
}

export default List
