import React, { useState } from 'react'
import cx from 'classnames'
import styles from './PrescriptionPrintPreview.module.scss'
import { findDOMNode } from 'react-dom';

import EyewaLogo from './../../../icons/eyewa-logo.png'
import OpticalClubLogo from './optical-club-logo.png'
import UAEStamp from './../../../icons/uae-stamp.png'
import KSAStamp from './../../../icons/ksa-stamp.png'
import { FIELDS } from 'common-hooks/useFields';
import { useSelector } from 'react-redux';
import { selectDefaults } from 'appSlice';
import dayjs from 'dayjs';

function PrescriptionPrintPreview({ containerRef, dialogRef, customerName, customerId, customerPhone, country, enableCheckboxes, ...printPreviewData }) {
  const defaultValues = useSelector(selectDefaults);
  const isOpticalClub = defaultValues.magentoStoreCode.includes('opcl')
  const [customerEditName, setCustomerEditName] = useState(printPreviewData?.name ? printPreviewData?.name : customerName)
  const [prescriptionDate, setPrescriptionDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [customerEditPhone, setCustomerEditPhone] = useState(customerPhone)

  const fractionValue = (number) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
      number
    )
  }

  const handlePrint = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const checkboxStates = {};
    checkboxes.forEach(checkbox => {
      checkboxStates[checkbox.id] = checkbox.checked;
    });
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      const stylesheets = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join('\n');
          } catch (error) {
            console.error('Error while parsing CSS:', error);
            return '';
          }
        })
        .join('\n');
      let printDOMNode = findDOMNode(containerRef.current)
      const content = printDOMNode.firstElementChild.childNodes[1].innerHTML;

      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>${stylesheets}</style>
            <style>
              @media print {
                .print-content {
                  display: block !important;
                }
                @page {
                  size: A5 !important;
                }
              }
              .print-content {
                display: none;
              }
            </style>
          </head>
          <body>
          ${content}
          <script>
                // Set the state of checkboxes in the new window
                const checkboxStates = ${JSON.stringify(checkboxStates)};
                document.addEventListener('DOMContentLoaded', () => {
                    for (const [id, state] of Object.entries(checkboxStates)) {
                        document.getElementById(id).checked = state;
                    }
                });
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
      dialogRef.current?.close();
    }
  };
  return (
    <div className='print-content'>
      <div className={cx(styles['print-preview'])}>
        <button onClick={handlePrint} className={styles.printButton}>Print</button>
        <img alt='logo' src={isOpticalClub ? OpticalClubLogo : EyewaLogo} className={styles.logo} />
        <div className={styles.customerDetails}>
          <span>{'Patient: '}</span>
          <input
            type='text'
            className={styles.customerName}
            defaultValue={`${printPreviewData?.name ? printPreviewData?.name : customerName}`}
            onChange={e => setCustomerEditName(e.target.value)}
            value={customerEditName}
          ></input>
          <span>
            <input
              type='date'
              className={cx(styles.customerName, styles.date)}
              defaultValue={dayjs().format('YYYY-MM-DD')}
              onChange={e => setPrescriptionDate(e.target.value)}
              value={prescriptionDate}
            ></input>
          </span>
          <p className={styles.customer}>{`Patient Number: ${customerId}`}</p>
          <span>{'Patient Phone: '}</span>
          <input
            type='text'
            className={styles.customerName}
            onChange={e => setCustomerEditPhone(e.target.value)}
            value={customerEditPhone}
          ></input>
        </div>
        <div className={styles.eyetestValues}>
          <table>
            <thead>
              <tr>
                <td colspan="2"></td>
                <th scope="col">Sphere</th>
                <th scope="col">Cylinder</th>
                <th scope="col">Axis</th>
                <th scope="col">Addition</th>
                <th scope="col">Prism</th>
                <th scope="col">PD</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles['td-center']}>
                <th colspan="2" >OD</th>
                <td>{fractionValue(printPreviewData.right.sphere)}</td>
                <td>{fractionValue(printPreviewData.right.cylinder)}</td>
                <td>{printPreviewData.right.axis}</td>
                <td>{fractionValue(printPreviewData.right.additionalPower)}</td>
                <td>{printPreviewData.right.segmentHeight}</td>
                <td>{printPreviewData.right.pupilDiameter}</td>
              </tr>
              <tr className={styles['td-center']}>
                <th colspan="2">OS</th>
                <td>{fractionValue(printPreviewData.left.sphere)}</td>
                <td>{fractionValue(printPreviewData.left.cylinder)}</td>
                <td>{printPreviewData.left.axis}</td>
                <td>{fractionValue(printPreviewData.left.additionalPower)}</td>
                <td>{printPreviewData.left.segmentHeight}</td>
                <td>{printPreviewData.left.pupilDiameter}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.addOnWrapper}>
          <div className={styles.lensTypeWrapper}>
            <h3>Lens Design</h3>
            {['Single Vision', 'Progressive', 'Contact lens', 'Plano'].map(lens => (
              <div className={styles.lensType}>
                <input type="checkbox" className={cx(styles.checkbox, { [styles.disabled]: !enableCheckboxes })} id={lens} name={lens} defaultChecked={printPreviewData.lensType?.toLowerCase() === lens.toLowerCase()} />
                <label for={lens} className={styles.lensTypeLabel}>{lens}</label>
              </div>
            ))}
          </div>
          <div className={styles.lensTypeWrapper}>
            <h3>Vision Enhancement</h3>
            {[
              { label: 'Blue Light', value: 'BL', addOnNum: FIELDS.ADDON_1 },
              { label: 'Photochromic', value: 'TR', addOnNum: FIELDS.ADDON_2 },
              { label: 'Tinting', value: 'TN', addOnNum: FIELDS.ADDON_3 },
              { label: 'Transitions', value: 'TS', addOnNum: FIELDS.ADDON_5 },
              { label: 'Mioeye', value: 'MOY', addOnNum: FIELDS.ADDON_6 },
              { label: 'Office', value: 'Office', addOnNum: FIELDS.ADDON_7 },
            ].map(addOn => (
              <div>
                <input type="checkbox"
                  className={cx(styles.checkbox, { [styles.disabled]: !enableCheckboxes })}
                  id={addOn.value}
                  name={addOn.value}
                  defaultChecked={addOn.value === printPreviewData[addOn.addOnNum]} />
                <label for={addOn.value} className={styles.lensTypeLabel}>{addOn.label}</label>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.notes}>
          {'Notes optometrist: ' + printPreviewData.comment?.comment}
        </div>
        <div className={styles.eStampWrapper}>
          <img alt='e-stamp' src={defaultValues.country === 'ae' ? UAEStamp : KSAStamp} className={styles.stamp} />
        </div>
        <div className={styles.signature}>
          {'Optometrist Signature'}
        </div>
      </div>
    </div>
  )
}

export default PrescriptionPrintPreview
