import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'components';
import useI18n from 'common-hooks/useI18n';
import cx from 'classnames';
import styles from './Review.module.scss';
import noResultsIcon from 'components/message/error.png';
import tableStyles from '../dashboard/Dashboard.module.scss';
import { useTable, useFlexLayout } from 'react-table';
import useReview from "./useReview";
import { useSelector } from 'react-redux';
import { selectToken } from 'features/login/loginSlice';

function Review() {
  const [reviewData, setReviewData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { i18n } = useI18n();
  const {
    isUninitialized,
    loading,
    // filters,
    // onFilterChange,
    // onFilterReset,
    hasError,
    error
  } = useReview();

    const token = useSelector(selectToken);

  const [selectedFilter, setSelectedFilter] = useState(''); // State for selected filter option

  // Function to filter reviewData based on selectedFilter
  const filteredData = reviewData.filter(item => {
    if (selectedFilter === '') return true; // Show all if no filter selected
    return item.reviewStatus === selectedFilter;
  });

  const hasNoResults = !isUninitialized && !loading && reviewData.length === 0;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: filteredData,
  }, useFlexLayout);

  const getTableData = async (pageNum = 0, size = 20) => {
    setIsLoading(true);
    try {
      let apiUrl = `${process.env.REACT_APP_CART_BASE_URL}/api/admin/v1/reviews/PRODUCT?page=${pageNum}&size=${size}`;

      // Add reviewStatus parameter based on selectedFilter
      if (selectedFilter === 'APPROVED') {
        apiUrl += '&reviewStatus=APPROVED';
      } else if (selectedFilter === 'REJECTED') {
        apiUrl += '&reviewStatus=REJECTED';
      } else if (selectedFilter === 'IN_REVIEW') {
        apiUrl += '&reviewStatus=IN_REVIEW';
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const data = await response.json();
      if (data.error) throw new Error(data.error);

      const temp = data.map(obj => ({
        ...obj,
        fullNameAndPhone: `${obj.nickName}\n(${obj.reviewCustomerDTO.name}, ${obj.reviewCustomerDTO.mobileNumber})`,
        customerLink: `${window.location.protocol}//${window.location.host}/customers/${obj.reviewCustomerDTO.id}/prescriptions/add`
      }));

      if (pageNum === 1) {
        setReviewData(temp);
      } else {
        setReviewData(prevData => [...prevData, ...temp]);
      }

      setHasMore(temp.length === size);

      // Define columns with dynamic cell rendering (if needed)
      const fetchedColumns = [
        { Header: 'Id', accessor: 'id' },
        { Header: 'Title', accessor: 'title' },
        { Header: 'Details', accessor: 'detail' },
        {
          Header: 'Nickname (Name , Phone)',
          accessor: 'fullNameAndPhone',
          Cell: ({ row }) => (
            <div style={{ whiteSpace: 'pre-line' }}>
              {row.original.nickName}
              <br />
              (<a href={row.original.customerLink} target="_blank" rel="noopener noreferrer">{row.original.reviewCustomerDTO.name}</a>, {row.original.reviewCustomerDTO.mobileNumber})
            </div>
          )
        },
        {
          Header: 'Medialist',
          accessor: 'mediaList',
          Cell: ({ cell }) => (
            cell.value && cell.value.length > 0 ? cell.value.map((media, index) => (
              <div key={index}>
                <a href={media} target="_blank" rel="noopener noreferrer">Media {index + 1}</a>
              </div>
            )) : ''
          )
        },
        { Header: 'Rating', accessor: 'rating' },
        { Header: 'Review Status', accessor: 'reviewStatus' },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }) => (
            <div className={styles.twoButton}>
              {(selectedFilter === 'IN_REVIEW' || selectedFilter === '') && (
                <>
                  <Button className={styles.acceptbtn} onClick={() => handleStatusChange(row.original, 'APPROVED')}>Accept</Button>
                  <Button className={styles.rejectbtn} onClick={() => handleStatusChange(row.original, 'REJECTED')}>Reject</Button>
                </>
              )}
              {selectedFilter === 'APPROVED' && (
                <Button className={styles.rejectbtn} onClick={() => handleStatusChange(row.original, 'REJECTED')}>Reject</Button>
              )}
              {selectedFilter === 'REJECTED' && (
                
                <Button className={styles.acceptbtn} onClick={() => handleStatusChange(row.original, 'APPROVED')}>Accept</Button>
              )}
            </div>
          )
        }
      ];

      setColumns(fetchedColumns);

    } catch (error) {
      console.log(error.message);
      alert("Got an error while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTableData(page);
  }, [page, selectedFilter]);

  const handleStatusChange = async (review, status) => {
    try {
      let apiUrl = `${process.env.REACT_APP_CART_BASE_URL}/api/admin/v1/reviews`;
      const response = await fetch(`${apiUrl}/${review.id}/${status}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      getTableData(0);
      console.log(`Review ID: ${review.id}, Status: ${status}`);
    } catch (error) {
      console.error(`Failed to update status for review ID ${review.id}:`, error.message);
    }
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <Layout
      title={i18n('Review')}
      wide
    >
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.filterSection)}>
          <div className={styles.filterLabel}>Filter</div>
          <div className={styles.filterDiv}>
            <label className={styles.filterLabel1}>Review Status</label>
            <select
              className={styles.filterDropdown}
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <option value="IN_REVIEW">In Review</option>
              <option value="APPROVED">Approved</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
        </div>
        {hasNoResults ? (
          <div className={cx(styles['no-results'])}>
            <img className={cx(styles['no-results-icon'])} src={noResultsIcon} alt="" />
            <p className={cx(styles['no-results-message'])}>{i18n('No results')}</p>
            <p className={cx(styles['no-results-info'])}>{i18n('Please try refining your search')}</p>
          </div>
        ) : (
          <>
            <div className={cx(tableStyles['table-wrapper'], styles['table-wrapper'])} id="tableContainer">
              <div {...getTableProps()} className={cx(tableStyles.table, styles.table)}>
                <div>
                  {headerGroups.map(headerGroup => (
                    <div {...headerGroup.getHeaderGroupProps()} className={cx(tableStyles['table-header'])}>
                      {headerGroup.headers.map(column => (
                        <div {...column.getHeaderProps([{ className: cx(tableStyles[column.className], tableStyles.th, styles.th) }])}>
                          {column.render('Header')}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                {isLoading ? <div className={cx(styles.loader)}><h3>Loading..</h3></div> :
                  <div {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row);
                      return (
                        <div {...row.getRowProps()} className={cx(tableStyles.row)}>
                          {row.cells.map(cell => (
                            <div {...cell.getCellProps([{ className: cx(tableStyles.cell, tableStyles[cell.column.className]) }])}>
                              {cell.render('Cell')}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>}
              </div>
            </div>
            {hasMore && <Button className={styles.load} size="small" onClick={handleLoadMore}>Load More</Button>}
          </>
        )}
      </div>
      {hasError && (
        <div className={cx(styles['no-results'])}>
          <span className={styles['error-info']}>{'Error: ' + error?.data?.message?.substring(0, 100) + '...'}</span>
        </div>
      )}
    </Layout>
  );
}

Review.propTypes = {}

export default Review;
